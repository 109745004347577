import { http, headers } from "utils";
import {
  SET_HOLDER_DETAILS,
  GET_HOLDER_CREDENTIALS_BEGIN,
  GET_HOLDER_CREDENTIALS_SUCCESS,
  GET_HOLDER_CREDENTIALS_FAILURE,
  SET_SEARCH_HOLDER_DETAILS,
  SET_SEARCH_PARAMS,
} from "./holders.constant";

export const setHolderDetails = (payload) => ({
  type: SET_HOLDER_DETAILS,
  payload,
});
export const setSearchHolderDetails = (payload) => ({
  type: SET_SEARCH_HOLDER_DETAILS,
  payload,
});
export const setSearchHolderParms = (payload) => ({
  type: SET_SEARCH_PARAMS,
  payload,
});

const getHolderCredentialsBegin = () => ({
  type: GET_HOLDER_CREDENTIALS_BEGIN,
});

const getHolderCredentialsSuccess = (payload) => ({
  type: GET_HOLDER_CREDENTIALS_SUCCESS,
  payload,
});

const getHolderCredentialsFailure = (payload) => ({
  type: GET_HOLDER_CREDENTIALS_FAILURE,
  payload,
});

export const getHolderCredentials = (params) => (dispatch) => {
  dispatch(getHolderCredentialsBegin());
  http("get", `/user/holder/credentials`, null, { headers, params }, true)
    .then((response) => {
      dispatch(getHolderCredentialsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getHolderCredentialsFailure(err?.response?.data?.message));
    });
};
