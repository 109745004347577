export const SET_HOLDER_DETAILS = "SET_HOLDER_DETAILS";
export const SET_SEARCH_HOLDER_DETAILS = "SET_SEARCH_HOLDER_DETAILS";
export const GET_HOLDER_CREDENTIALS_BEGIN = "GET_HOLDER_CREDENTIALS_BEGIN";
export const GET_HOLDER_CREDENTIALS_SUCCESS = "GET_HOLDER_CREDENTIALS_SUCCESS";
export const GET_HOLDER_CREDENTIALS_FAILURE = "GET_HOLDER_CREDENTIALS_FAILURE";
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const GET_SEARCH_HOLDER_CREDENTIALS_BEGIN =
  "GET_SEARCH_HOLDER_CREDENTIALS_BEGIN";
export const GET_SEARCH_HOLDER_CREDENTIALS_SUCCESS =
  "GET_SEARCH_HOLDER_CREDENTIALS_SUCCESS";
export const GET_SEARCH_HOLDER_CREDENTIALS_FAILURE =
  "GET_SEARCH_HOLDER_CREDENTIALS_FAILURE";
